import { loadLinksInteraction } from "./interaction";
import { loadLinksPlugin } from "./plugin";
export async function loadParticlesLinksInteraction(engine, refresh = true) {
    await loadLinksInteraction(engine, refresh);
    await loadLinksPlugin(engine, refresh);
}
export * from "./Options/Classes/Links";
export * from "./Options/Classes/LinksShadow";
export * from "./Options/Classes/LinksTriangle";
export * from "./Options/Interfaces/ILinks";
export * from "./Options/Interfaces/ILinksShadow";
export * from "./Options/Interfaces/ILinksTriangle";
